import React from 'react';
import { connect } from 'react-redux';

import PlacementRow from './PlacementRow';
import './PlacementBoard.scss';

class PlacementBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getGrid() {
    const { placementMatrix } = this.props;
    const rows = [];
    placementMatrix.forEach((colors, index) => {
      rows.push(
        <PlacementRow
          key={index}
          index={index}
          playerId={this.props.playerId}
        />
      );
    });
    return rows;
  }

  render() {
    return <div className="placement-board">{this.getGrid()}</div>;
  }
}

const mapStateToProps = (state) => ({
  placementMatrix: state.azulReducer.placementMatrix,
});

export default connect(mapStateToProps)(PlacementBoard);
