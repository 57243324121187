import React from 'react';

import './Tile.scss';

class Tile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { color, inactive, selected, onClick, clickable } = this.props;

    const minusOne = color === -1;
    const empty = !color || minusOne;

    return (
      <div
        className={[
          'tile',
          inactive ? 'inactive' : '',
          empty ? 'empty' : '',
          minusOne ? 'minus-one' : '',
          selected ? 'selected' : '',
          clickable ? 'clickable' : '',
        ].join(' ')}
        style={
          minusOne
            ? {}
            : empty
            ? { background: 'none' }
            : {
                background: color,
                // background: `linear-gradient(45deg, ${color} 0%, white 100%)`,
              }
        }
        disabled={inactive}
        onClick={onClick}
      >
        {minusOne ? 1 : ''}
      </div>
    );
  }
}

export default Tile;
