import './Layout.scss';

function Layout(props) {
  const { navBar, navBarMiddle, navBarRight, leftSide, body } = props;

  return (
    <div className="layout">
      <div className="nav-bar">
        {navBar ? <div className="left">{navBar}</div> : ''}
        {navBarMiddle ? <div className="middle">{navBarMiddle}</div> : ''}
        {navBarRight ? <div className="right">{navBarRight}</div> : ''}
      </div>
      <div className={['main', !leftSide ? 'no-left-side' : ''].join(' ')}>
        {leftSide ? <div className="left">{leftSide}</div> : ''}
        <div className="body">{body}</div>
      </div>
    </div>
  );
}

export default Layout;
