import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import './ScoreBoard.scss';
import AzulPill from './components/AzulPill';

function PlayerRow(props) {
  const { player, color, rank } = props;
  return (
    <tr>
      <td>{rank}</td>
      <td>
        <div className="cell">
          <AzulPill color={color} />
          <div className="player-name">{player.name}</div>
        </div>
      </td>
      <td>{player.runningScore + player.specialScore}</td>
    </tr>
  );
}

class ScoreBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPlayers() {
    const { players, tiles } = this.props;

    const playersCopy = [...players];

    return playersCopy
      .map((player, index) => ({
        ...player,
        index,
      }))
      .sort(
        (p1, p2) =>
          p2.runningScore +
          p2.specialScore -
          (p1.runningScore + p1.specialScore)
      )
      .map((player, index) => (
        <PlayerRow
          key={player.index}
          rank={index + 1}
          player={player}
          color={tiles[player.index]}
        />
      ));
  }

  render() {
    return (
      <div className="score-board">
        <table>
          <tr>
            <td>{this.props.t('Rank')}</td>
            <td>{this.props.t('Player')}</td>
            <td>{this.props.t('Score')}</td>
          </tr>
          {this.getPlayers()}
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  players: state.azulReducer.players,
  tiles: state.azulReducer.tiles,
});

export default connect(mapStateToProps, {})(withTranslation()(ScoreBoard));
