import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Tile from './components/Tile';

import {
  assignSelectedTilesToOverflow,
  computeRunningScore,
} from './redux/azulSlice';

import './Overflow.scss';

class Overflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTiles() {
    const { overflow, tileSet } = this.props;

    const FILLER_SIZE = 5;

    const tiles =
      overflow.length >= FILLER_SIZE
        ? overflow
        : [...overflow, ...Array(FILLER_SIZE - overflow.length).fill('')];

    return tiles.map((tile, index) => (
      <Tile
        key={index}
        color={tileSet[tile] || tile}
        inactive={tile === ''}
      ></Tile>
    ));
  }

  async handleOverflowClicked() {
    await this.props.assignSelectedTilesToOverflow();
    await this.props.computeRunningScore();
  }

  render() {
    return (
      <div className="overflow" onClick={() => this.handleOverflowClicked()}>
        {this.props.t('Overflow')}:{this.getTiles()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  tileSet: state.azulReducer.tiles,
  overflow: state.azulReducer.players[ownProps.playerId]?.overflow || [],
});

export default connect(mapStateToProps, {
  assignSelectedTilesToOverflow,
  computeRunningScore,
})(withTranslation()(Overflow));
