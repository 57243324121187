import React from 'react';
import { connect } from 'react-redux';
import AzulButton from './components/AzulButton';

import { withTranslation } from 'react-i18next';

import { faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from './components/Modal';

import { setTiles } from './redux/azulSlice';
import Tile from './components/Tile';
import FactoryCircle from './components/FactoryCircle';

// import './Settings.scss';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      updatedTiles: {},
    };
  }

  openSettings() {
    this.setState({
      modalOpened: true,
    });
  }

  closeSettings() {
    this.setState({
      modalOpened: false,
    });
  }

  async saveSettings() {
    this.closeSettings();

    this.props.setTiles(this.getTiles());
  }

  newGame() {
    this.setState({
      modalOpened: true,
    });
  }

  getHelpPage() {
    const { t, tiles } = this.props;

    return [
      <div>{t('Azul is a board game for 2, 3, or 4 players.')}</div>,
      <div>
        {t(
          'There are 20x 5 color tiles (100 tiles) in a bag, which are randomly assigned to factories.'
        )}
      </div>,
      <div style={{ display: 'inline-flex' }}>
        {tiles.map((tile) => (
          <Tile color={tile} />
        ))}
      </div>,
      <div>
        {t(
          'Factories contain 4 tiles. When it is your turn, you will pick tiles of the same color in single factory.'
        )}
      </div>,
      <div style={{ display: 'inline-flex' }}>
        <FactoryCircle
          tiles={[0, 0, 1, 2]}
          tileSet={tiles}
          selectedTile={null}
        />
        <FactoryCircle tiles={[1, 0, 1, 3]} tileSet={tiles} selectedTile={0} />
      </div>,
      <div>
        {t('Place the tiles you picked in the staging rows next to the board.')}
      </div>,
      <div>
        {t(
          'At the end of the round, any full staging rows will load the color in the placement board.'
        )}
      </div>,
      <div>
        {t(
          'Your goal is to get as many tiles loaded in the placement board as possible.'
        )}
      </div>,
      <ul>
        <li>
          {t(
            'You will score 1 extra point for any consecutive tile placed on the board.'
          )}
        </li>
        <li>
          {t('You will score an extra 2 points for each full horizontal row.')}
        </li>
        <li>
          {t('You will score an extra 7 points for each full vertical row.')}
        </li>
        <li>
          {t(
            'You will score an extra 10 points for each full set of a color (one on each row).'
          )}
        </li>
      </ul>,
      <div>
        {t(
          'Any tile in the overflow will cost you points: -1 for each of the first two tiles, -2 for each of the next three, -3 for each of the next four tiles, etc, etc.'
        )}
      </div>,
      // <div>{t('blah')}</div>,
    ];
  }

  render() {
    return (
      <div className="help">
        <AzulButton
          icon={faQuestion}
          onClick={() => this.openSettings()}
          label={this.props.t('Help')}
        />

        {/* ~~~~~~~~ Modal ~~~~~~~~ */}
        <Modal
          isOpened={this.state.modalOpened}
          withOverlay
          onOverlayClick={() => this.closeSettings()}
          header={this.props.t('Help')}
          // left={<AzulButton label="Blah" primary />}
          footer={[
            <AzulButton
              icon={faTimes}
              onClick={() => this.closeSettings()}
              label={this.props.t('Close')}
            />,
          ]}
        >
          {this.getHelpPage()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tiles: state.azulReducer.tiles,
});

export default connect(mapStateToProps, {
  setTiles,
})(withTranslation()(Settings));
