import React from 'react';

import './AzulSegmentedControl.scss';

function AzulSegmentedControl(props) {
  const { options, selected } = props;

  const buttons = [];

  for (const index in options) {
    const option = options[index];
    const { label } = option;
    buttons.push(
      <button
        key={index}
        className={[+index === +selected ? 'selected' : ''].join(' ')}
        onClick={() => props.onOptionClick?.(index)}
      >
        {label}
      </button>
    );
  }

  return <div className="azul-segmented-control">{buttons}</div>;
}

export default AzulSegmentedControl;
