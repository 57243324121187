import React from 'react';
import { connect } from 'react-redux';

import StageRow from './StageRow';
import './Stage.scss';

class Stage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getStageRows() {
    const { tiles } = this.props;
    return tiles.map((tile, index) => (
      <StageRow
        key={index}
        index={index}
        playerId={this.props.playerId}
        max={index + 1}
        disabled={this.props.disabled}
      />
    ));
  }

  render() {
    return <div className="stage">{this.getStageRows()}</div>;
  }
}

const mapStateToProps = (state) => ({
  tiles: state.azulReducer.tiles,
});

export default connect(mapStateToProps)(Stage);
