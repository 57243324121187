import React from 'react';
import ReactDOM from 'react-dom';

import './Modal.scss';

// These two containers are siblings in the DOM
const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { withOverlay, style, onOverlayClick, isOpened } = this.props;

    if (isOpened)
      return ReactDOM.createPortal(
        <div className="modal">
          {withOverlay ? (
            <div
              className="overlay"
              style={style?.overlay}
              onClick={onOverlayClick}
            />
          ) : (
            ''
          )}
          <div
            className={['content', this.props.left ? '' : 'no-left'].join(' ')}
            style={style?.content}
          >
            {this.props.left ? (
              <div className="left">{this.props.left}</div>
            ) : (
              ''
            )}
            <div
              className={['right', this.props.header ? '' : 'no-header'].join(
                ' '
              )}
            >
              {this.props.header ? (
                <div className="header">{this.props.header}</div>
              ) : (
                ''
              )}
              <div className="body">{this.props.children}</div>
              <div className="footer">{this.props.footer}</div>
            </div>
          </div>
        </div>,
        this.el
      );
    else return <div></div>;
  }
}

export default Modal;
