import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from './components/Modal';
import AzulButton from './components/AzulButton';
import AzulInput from './components/AzulInput';
import AzulSegmentedControl from './components/AzulSegmentedControl';

import {
  initializeBoard,
  initBagOfTiles,
  newGame,
  addPlayer,
  setNumberOfPlayers,
  setNumberOfFactories,
} from './redux/azulSlice';

const GAME_OPTIONS = Object.freeze([
  {
    label: 'Two players',
    numberOfFactories: 5,
    numberOfPlayers: 2,
  },
  {
    label: 'Three players',
    numberOfFactories: 7,
    numberOfPlayers: 3,
  },
  {
    label: 'Four players',
    numberOfFactories: 9,
    numberOfPlayers: 4,
  },
]);

function PlayerList(props) {
  const { players, numberOfPlayers, onPlayerNameChanged, t } = props;

  const playerList = [];

  for (let i = 0; i < numberOfPlayers; i++) {
    const player = players[i];
    playerList.push(
      <AzulInput
        key={i}
        label={`${t('Player')} ${i + 1}`}
        value={player}
        onChange={(e) =>
          onPlayerNameChanged?.({ index: i, playerName: e.target.value })
        }
      />
    );
  }

  return <div>{playerList}</div>;
}

class NewGameButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      optionSelected: 0,
      playerNames: [],
    };
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  async createNewGame() {
    this.closeModal();

    await this.props.newGame();
    await this.props.setNumberOfPlayers(
      GAME_OPTIONS[this.state.optionSelected].numberOfPlayers
    );
    await this.props.setNumberOfFactories(
      GAME_OPTIONS[this.state.optionSelected].numberOfFactories
    );
    await this.props.initBagOfTiles();
    await this.props.initializeBoard();

    for (const name of this.getPlayers()) {
      await this.props.addPlayer({ name });
    }
  }

  openModal() {
    this.setState({
      modalOpened: true,
    });
  }

  handleOptionSelected(i) {
    this.setState({
      optionSelected: i,
    });
  }

  handlePlayerNameChanged({ index, playerName }) {
    const playerNamesCopy = [...this.state.playerNames];
    playerNamesCopy[index] = playerName;
    this.setState({
      playerNames: playerNamesCopy,
    });
  }

  hasEmptyPlayerName() {
    return this.getPlayers().filter((name) => !name).length > 0;
  }

  getPlayers() {
    const playerNames = Array(
      GAME_OPTIONS[this.state.optionSelected].numberOfPlayers
    ).fill('');
    for (let i = 0; i < playerNames.length; i++) {
      playerNames[i] =
        this.state.playerNames[i] || this.props.players[i]?.name || '';
    }
    return playerNames;
  }

  render() {
    return (
      <div className="new-game-button">
        <AzulButton
          icon={faPlus}
          onClick={() => this.openModal()}
          label={this.props.t('New Game')}
        />

        {/* ~~~~~~~~ Modal ~~~~~~~~ */}
        <Modal
          isOpened={this.state.modalOpened}
          withOverlay
          onOverlayClick={() => this.closeModal()}
          header={
            <AzulSegmentedControl
              options={GAME_OPTIONS.reduce((previousValue, currentValue) => {
                return [
                  ...previousValue,
                  { ...currentValue, label: this.props.t(currentValue.label) },
                ];
              }, [])}
              selected={this.state.optionSelected}
              onOptionClick={(i) => this.handleOptionSelected(i)}
            />
          }
          footer={[
            <AzulButton
              icon={faTimes}
              onClick={() => this.closeModal()}
              label={this.props.t('No, Cancel')}
            />,
            <AzulButton
              icon={faPlus}
              primary
              onClick={() => this.createNewGame()}
              label={this.props.t('Yes, Create New Game')}
              disabled={this.hasEmptyPlayerName()}
            />,
          ]}
        >
          <PlayerList
            players={this.getPlayers()}
            t={this.props.t}
            numberOfPlayers={
              GAME_OPTIONS[this.state.optionSelected].numberOfPlayers
            }
            onPlayerNameChanged={(e) => this.handlePlayerNameChanged(e)}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  players: state.azulReducer.players,
});

export default connect(mapStateToProps, {
  initializeBoard,
  initBagOfTiles,
  newGame,
  addPlayer,
  setNumberOfPlayers,
  setNumberOfFactories,
})(withTranslation()(NewGameButton));
