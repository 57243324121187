// import logo from './logo.svg';
import './App.css';

import Game from './Game';

// TODO: remove the tiles prop underneath and switch to store/settings
function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* $charcoal: #264653ff;
$persian-green: #2a9d8fff;
$orange-yellow-crayola: #e9c46aff;
$sandy-brown: #f4a261ff;
$burnt-sienna: #e76f51ff; */}
        {/* --mango: #ffbe0bff;
--orange-pantone: #fb5607ff;
--winter-sky: #ff006eff;
--blue-violet: #8338ecff;
--azure: #3a86ffff; */}
        <Game tiles={['#ffbe0b', '#fb5607', '#ff006e', '#8338ec', '#3a86ff']} />
        {
          // <Game
          //   tiles={['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51']}
          // />
        }
        {/* <Game tiles={['Blue', 'Yellow', 'Red', 'Grey', 'Green']}></Game> */}
      </header>
    </div>
  );
}

export default App;
