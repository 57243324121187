import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'New Game': 'New Game',
      board: "{{player}}'s board",
    },
  },
  fr: {
    translation: {
      'New Game': 'Nouvelle Partie',
      board: 'La planche de {{player}}',
      Settings: 'Paramètres',
      Color: 'Couleur',
      Save: 'Enregistrer',
      Cancel: 'Annuler',
      'Yes, Create New Game': 'Oui, Créer une nouvelle partie',
      'No, Cancel': 'Non, Annuler',
      Player: 'Joueur',
      'Welcome to Azul! Press the "new game" button to start.':
        'Bienvenu dans le jeu Azul! Appuyez sur le bouton "nouvelle partie" pour commencer à jouer.',
      'End of round, press the "next round" button':
        'Fin du tour, appuyez sur le bouton "tour suivant" pour continuer',
      'Two players': 'Deux Joueurs',
      'Three players': 'Trois Joueurs',
      'Four players': 'Quatre Joueurs',
      'it is your turn to play!': "c'est à votre tour de jouer!",
      'End of game! GG': 'Fin de la partie! Bien joué!',
      Rank: 'Rang',
      Score: 'Score',
      Overflow: 'Surplus',
      Help: 'Aide',
      'Next Round': 'Tour suivant',
      'Azul is a board game for 2, 3, or 4 players.':
        'Azul est un jeu de société pour 2, 3 ou 4 joueurs.',
      'There are 20x 5 color tiles (100 tiles) in a bag, which are randomly assigned to factories.':
        'Il y a 20x 5 tuiles de couleur (100 tuiles) dans un sac, qui sont assignées au hasard aux usines.',
      'Factories contain 4 tiles. When it is your turn, you will pick tiles of the same color in single factory.':
        "Les usines contiennent 4 tuiles. Quand c'est votre tour, vous choisirez des tuiles de la même couleur dans une seule usine.",
      'Place the tiles you picked in the staging rows next to the board.':
        'Placez les tuiles que vous avez choisies dans les rangées de mise en scène à côté du plateau.',
      'At the end of the round, any full staging rows will load the color in the placement board.':
        'À la fin du tour, toutes les lignes de mise en scène complètes chargeront la couleur dans le tableau de placement.',
      'Your goal is to get as many tiles loaded in the placement board as possible.':
        'Votre objectif est de placer autant de tuiles que possible dans le tableau de placement.',
      'You will score 1 extra point for any consecutive tile placed on the board.':
        'Vous marquerez 1 point supplémentaire pour toute tuile consécutive placée sur le plateau.',
      'You will score an extra 2 points for each full horizontal row.':
        'Vous marquerez 2 points supplémentaires pour chaque rangée horizontale complète.',
      'You will score an extra 7 points for each full vertical row.':
        'Vous marquerez 7 points supplémentaires pour chaque rangée verticale complète.',
      'You will score an extra 10 points for each full set of a color (one on each row).':
        "Vous marquerez 10 points supplémentaires pour chaque ensemble complet d'une couleur (un sur chaque ligne).",
    },
  },
  es: {
    translation: {
      'New Game': 'Nuevo juego',
      board: 'El tablero de {{player}}',
      Settings: 'Configuración',
      Color: 'Color',
      Save: 'Guardar',
      Cancel: 'Cancelar',
      'Yes, Create New Game': 'Sí, crear juego nuevo',
      'No, Cancel': 'No, Cancelar',
      Player: 'Jugador',
      'Welcome to Azul! Press the "new game" button to start.':
        '¡Bienvenidos al juego Azul! Pulsa el botón "Nuevo juego" para empezar a jugar. ',
      'End of round, press the "next round" button':
        'Fin de la ronda, presione el botón "siguiente ronda" para continuar',
      'Two players': 'Dos jugadores',
      'Three players': 'Tres jugadores',
      'Four players': 'Cuatro jugadores',
      'it is your turn to play!': '¡es tu turno de jugar!',
      'End of game! GG': ' ¡Fin del juego! ¡Bien jugado!',
      Rank: 'Rango',
      Score: 'Score',
      Overflow: 'Excedente',
      Help: 'Ayuda',
      'Next Round': 'Siguiente ronda',
      'Azul is a board game for 2, 3, or 4 players.':
        'Azul es un juego de mesa para 2, 3 o 4 jugadores.',
      'There are 20x 5 color tiles (100 tiles) in a bag, which are randomly assigned to factories.':
        'Hay 20 fichas de 5 colores (100 fichas) en una bolsa, que se asignan aleatoriamente a las fábricas',
      'Factories contain 4 tiles. When it is your turn, you will pick tiles of the same color in single factory.':
        'Las fábricas contienen 4 fichas. Cuando sea tu turno, elegirás fichas del mismo color en una sola fábrica.',
      'Place the tiles you picked in the staging rows next to the board.':
        'Coloque las fichas que eligió en las filas de preparación junto al tablero.',
      'At the end of the round, any full staging rows will load the color in the placement board.':
        'Al final de la ronda, cualquier fila de preparación completa cargará el color en el tablero de colocación.',
      'Your goal is to get as many tiles loaded in the placement board as possible.':
        'Tu objetivo es cargar tantas fichas como sea posible en el tablero de colocación.',
      'You will score 1 extra point for any consecutive tile placed on the board.':
        'Obtendrá 1 punto extra por cualquier ficha consecutiva colocada en el tablero.',
      'You will score an extra 2 points for each full horizontal row.':
        'Obtendrá 2 puntos adicionales por cada fila horizontal completa.',
      'You will score an extra 7 points for each full vertical row.':
        'Obtendrá 7 puntos adicionales por cada fila vertical completa.',
      'You will score an extra 10 points for each full set of a color (one on each row).':
        'Obtendrá 10 puntos adicionales por cada juego completo de un color (uno en cada fila).',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
