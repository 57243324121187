import React from 'react';
import { connect } from 'react-redux';
import AzulButton from './components/AzulButton';

import { withTranslation } from 'react-i18next';

import Modal from './components/Modal';

const LANGUAGES = Object.freeze([
  { label: 'English', iso: 'en', icon: 'us' },
  { label: 'Français', iso: 'fr', icon: 'fr' },
  { label: 'Español', iso: 'es', icon: 'es' },
]);

class ChangeLanguageButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      updatedTiles: {},
    };
  }

  openSettings() {
    this.setState({
      modalOpened: true,
    });
  }

  closeSettings() {
    this.setState({
      modalOpened: false,
    });
  }

  changeLanguage(iso) {
    this.props.i18n.changeLanguage(iso);
    this.closeSettings();
  }

  getLanguageButtons() {
    return LANGUAGES.map((language, index) => (
      <AzulButton
        key={index}
        flag={language.icon}
        onClick={() => this.changeLanguage(language.iso)}
        label={language.label}
      />
    ));
  }

  render() {
    const currentLanguageIso = LANGUAGES.find(
      (lang) => lang.iso === this.props.i18n.language
    ).icon;

    return (
      <div className="change-language-button">
        <AzulButton
          onClick={() => this.openSettings()}
          flag={currentLanguageIso}
        />

        {/* ~~~~~~~~ Modal ~~~~~~~~ */}
        <Modal
          isOpened={this.state.modalOpened}
          withOverlay
          onOverlayClick={() => this.closeSettings()}
        >
          {this.getLanguageButtons()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  withTranslation()(ChangeLanguageButton)
);
