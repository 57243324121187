import React from 'react';
import { connect } from 'react-redux';
import AzulButton from './components/AzulButton';

import { withTranslation } from 'react-i18next';

import { faSave, faTimes, faCog } from '@fortawesome/free-solid-svg-icons';

// import Layout from './components/Layout';
import Modal from './components/Modal';
import AzulInput from './components/AzulInput';

import { setTiles } from './redux/azulSlice';
import Tile from './components/Tile';

import './Settings.scss';

function TilePicker(props) {
  const tiles = [];

  for (const index in props.tiles) {
    const tile = props.tiles[index];
    tiles.push(
      <div key={index} className="color">
        <Tile color={tile} />
        <AzulInput
          label={props.t('Color')}
          value={tile}
          onChange={(e) => props.onTileChange({ index, value: e.target.value })}
        />
        <AzulInput
          type="color"
          value={tile}
          onChange={(e) => props.onTileChange({ index, value: e.target.value })}
        />
      </div>
    );
  }

  return <div className="tile-picker">{tiles}</div>;
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      updatedTiles: {},
    };
  }

  openSettings() {
    this.setState({
      modalOpened: true,
    });
  }

  closeSettings() {
    this.setState({
      modalOpened: false,
    });
  }

  async saveSettings() {
    this.closeSettings();

    this.props.setTiles(this.getTiles());
  }

  newGame() {
    this.setState({
      modalOpened: true,
    });
  }

  handleTileChange({ index, value }) {
    this.setState({
      updatedTiles: {
        ...this.state.updatedTiles,
        [`${index}`]: value,
      },
    });
  }

  getTiles() {
    const newTiles = [...this.props.tiles];
    for (const updatedTile in this.state.updatedTiles) {
      newTiles[updatedTile] = this.state.updatedTiles[updatedTile];
    }
    return newTiles;
  }

  render() {
    return (
      <div className="settings">
        <AzulButton
          icon={faCog}
          onClick={() => this.openSettings()}
          label={this.props.t('Settings')}
        />

        {/* ~~~~~~~~ Modal ~~~~~~~~ */}
        <Modal
          isOpened={this.state.modalOpened}
          withOverlay
          onOverlayClick={() => this.closeSettings()}
          header={this.props.t('Settings')}
          footer={[
            <AzulButton
              icon={faTimes}
              onClick={() => this.closeSettings()}
              label={this.props.t('Cancel')}
            />,
            <AzulButton
              icon={faSave}
              primary
              onClick={() => this.saveSettings()}
              label={this.props.t('Save')}
            />,
          ]}
        >
          <TilePicker
            tiles={this.getTiles()}
            onTileChange={(e) => this.handleTileChange(e)}
            t={this.props.t}
          />

          {/* <Layout
            body={'WIP - load settings / players'}
            bottom-right={[
              <AzulButton
                onClick={() => this.closeSettings()}
                label="Cancel"
              />,
              <AzulButton
                primary
                onClick={() => this.saveSettings()}
                label="Save"
              />,
            ]} 
          />*/}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tiles: state.azulReducer.tiles,
});

export default connect(mapStateToProps, {
  setTiles,
})(withTranslation()(Settings));
