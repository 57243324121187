import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import './AzulButton.scss';

function AzulButton(props) {
  const { primary, disabled, icon, flag, label, onClick } = props;

  return (
    <button
      className={[
        'azul',
        primary ? 'primary' : '',
        disabled ? 'disabled' : '',
      ].join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : ''}
      {flag ? getUnicodeFlagIcon(flag) : ''}
      <div className={['label', icon || flag ? 'has-icon' : ''].join(' ')}>
        {label}
      </div>
    </button>
  );
}

export default AzulButton;
