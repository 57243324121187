import React from 'react';

import Tile from './Tile';

import './FactoryCircle.scss';

class FactoryCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  countOccurences(array, match) {
    let count = 0;
    for (const item of array) {
      if (item === match) count++;
    }
    return count;
  }

  handleTileSelected(index) {
    const { tiles, onSelected } = this.props;
    if (tiles[index] !== -1) {
      const count = this.countOccurences(tiles, tiles[index]);
      if (onSelected) onSelected({ color: tiles[index], count });
    }
  }

  getTiles() {
    const { tiles = [], selectedTile, tileSet = [] } = this.props;
    return tiles.map((tile, index) => (
      <Tile
        key={index}
        color={tileSet[tile] || tile}
        clickable={tile !== -1}
        onClick={() => this.handleTileSelected(index)}
        inactive={selectedTile !== null && selectedTile !== tile}
        selected={selectedTile !== null && selectedTile === tile}
      />
    ));
  }

  render() {
    const { selectedTile, tiles } = this.props;
    return (
      <div
        className={[
          'factory-circle',
          selectedTile ? 'selected' : '',
          tiles?.length ? '' : 'hidden',
        ].join(' ')}
      >
        {this.getTiles()}
      </div>
    );
  }
}

export default FactoryCircle;
