import { createSlice } from '@reduxjs/toolkit';

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const initialState = {
  factories: [],
  selected: null,
  middle: [-1],
  bagOfTile: [],
  graveYard: [],
  numberOfFactories: 5,
  numberOfPlayers: 2,
  players: [],
  activePlayer: -1,
  endOfGame: false,
};

const nextPlayer = (state) => {
  state.activePlayer = (state.activePlayer + 1) % state.numberOfPlayers;
};

const cleanupTiles = (state) => {
  const { selected, players, activePlayer, middle, factories } = state;
  const player = players[activePlayer];

  if (selected.index > -1) {
    // Move tiles not selected from factory to middle
    middle.push(
      ...factories[selected.index].filter((tile) => tile !== selected.color)
    );

    factories[selected.index] = []; // empty factory
  } else {
    // middle is selected
    if (middle.indexOf(-1) >= 0) player.overflow.push(-1);
    state.middle = middle.filter(
      (tile) => tile !== selected.color && tile !== -1
    );
  }
  state.selected = null; // empty selection
  nextPlayer(state);
};

const computeGrid = (state) => {
  const { tiles } = state;
  const matrix = [];
  let row = [...Array(tiles.length).keys()];
  for (let i = 0; i < tiles.length; i++) {
    const colors = [...row];
    matrix[i] = colors;
    row = row.concat(row.splice(0, tiles.length - 1));
  }
  state.placementMatrix = matrix;
};

const deepMatrixCopy = (matrix) => {
  const newMatrix = [];
  for (const rowIndex in matrix) {
    newMatrix[rowIndex] = [];
    for (const colIndex in matrix[rowIndex]) {
      newMatrix[rowIndex][colIndex] = matrix[rowIndex][colIndex];
    }
  }
  return newMatrix;
};

const computeScore = ({ placementMatrix, player }) => {
  const { stages, placements, overflow, name } = player;
  console.log('--------------------------------------');
  console.log('Scoring for', name);
  let newPlacements = deepMatrixCopy(placements);
  let placementMatrixCopy = deepMatrixCopy(placementMatrix);

  let newPlayerScore = +player.score;
  for (const index in stages) {
    const stage = stages[index];
    if (+stage.count >= +index + 1) {
      const colorIndex = placementMatrixCopy[index].indexOf(stage.color);
      console.log('color', stage.color, 'index', colorIndex);
      newPlacements[index][colorIndex] = true;
      newPlayerScore += 1;
      // Check left
      for (let i = colorIndex - 1; i >= 0 && newPlacements[index]?.[i]; i--) {
        newPlayerScore += 1;
      }
      // Check right
      for (
        let i = colorIndex + 1;
        i < newPlacements.length && newPlacements[index]?.[i];
        i++
      ) {
        newPlayerScore += 1;
      }
      // Check up
      for (let i = index - 1; i >= 0 && newPlacements[i]?.[colorIndex]; i--) {
        newPlayerScore += 1;
      }
      // Check down
      for (
        let i = index + 1;
        i < newPlacements.length && newPlacements[i]?.[colorIndex];
        i++
      ) {
        newPlayerScore += 1;
      }
      console.log('newPlacements', newPlacements);
      console.log('score', newPlayerScore);
    }
  }

  let newEndGame = false;
  let newSpecialScore = 0;
  for (let i = 0; i < placementMatrix.length; i++) {
    // full rows
    let match = true;
    for (let j = 0; j < placementMatrix.length; j++) {
      match = match && newPlacements[i][j];
    }
    if (match) {
      newSpecialScore += 2;
      newEndGame = true;
    }
  }
  for (let i = 0; i < placementMatrix.length; i++) {
    // full columns
    let match = true;
    for (let j = 0; j < placementMatrix.length; j++) {
      match = match && newPlacements[j][i];
    }
    if (match) newSpecialScore += 7;
  }
  let allColors = Array(placementMatrix.length).fill(true);
  for (let i = 0; i < placementMatrix.length; i++) {
    for (let j = 0; j < placementMatrix.length; j++) {
      allColors[j] =
        allColors[j] && newPlacements[i][(i + j) % placementMatrix.length];
    }
  }
  newSpecialScore += allColors.filter((color) => color).length * 10;

  const overflowWeightsFn = (index) => {
    let weights = [];
    for (let i = 0; index >= weights.length; i++) {
      weights.push(...Array(i + 2).fill(-(i + 1)));
    }
    return weights[index];
  };
  for (let i = 0; i < overflow.length; i++) {
    newPlayerScore += overflowWeightsFn(i);
  }

  console.log('final new score', newPlayerScore, '+ special', newSpecialScore);

  return { newPlayerScore, newPlacements, newSpecialScore, newEndGame };
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export const azulSlice = createSlice({
  name: 'azul',
  initialState: {
    ...initialState,
    placementMatrix: [],
    tiles: [],
    tutorialMode: true,
  },
  reducers: {
    toggleTutorialModel: (state) => {
      state.tutorialMode = !state.tutorialMode;
    },
    setTiles: (state, action) => {
      state.tiles = action.payload;
      computeGrid(state);
    },
    setNumberOfPlayers: (state, action) => {
      state.numberOfPlayers = action.payload;
    },
    setNumberOfFactories: (state, action) => {
      state.numberOfFactories = action.payload;
    },
    newGame: (state) => {
      Object.assign(state, {
        ...initialState,
      });
    },
    initBagOfTiles: (state) => {
      const { tiles } = state;
      let bag = [];
      bag.length = tiles.length * 20;
      let start = 0;
      for (let i = 0; i < tiles.length; i++) {
        bag.fill(i, start, start + 20);
        start += 20;
      }
      state.bagOfTile = shuffle(bag);
    },
    initializeBoard: (state) => {
      const { bagOfTile, numberOfFactories } = state;
      const bag = [...shuffle(bagOfTile)];
      // Setting up each factory, and assign 4 tiles to each one of them
      for (let i = 0; i < numberOfFactories; i++) {
        state.factories[i] = [];
        for (let j = 0; j < 4 && bag.length > 0; j++) {
          state.factories[i].push(bag.pop());
        }
      }
      state.bagOfTile = bag;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    addFactory: (state, action) => {
      state.factories.push(action.payload);
    },
    assignSelectedTilesToStageRow: (state, action) => {
      const { payload: stage } = action;
      const { selected, players, activePlayer } = state;

      const player = players[activePlayer];

      const existingCount = player.stages[stage]?.count || 0;

      if (selected) {
        player.stages[stage] = {
          color: selected.color,
          count: selected.count + existingCount,
        };
        // overflow
        const overflow = selected.count + existingCount - (stage + 1);
        if (overflow > 0) {
          for (let i = 0; i < overflow; i++) {
            player.overflow.push(selected.color);
          }
        }
        cleanupTiles(state);
      }
    },
    assignSelectedTilesToOverflow: (state) => {
      const { selected, players, activePlayer } = state;
      const player = players[activePlayer];

      if (selected) {
        for (let i = 0; i < selected.count; i++) {
          player.overflow.push(selected.color);
        }
        cleanupTiles(state);
      }
    },
    addPlayer: (state, action) => {
      const {
        payload: { name },
      } = action;
      const { placementMatrix, tiles } = state;
      const placements = Array(tiles.length);
      placementMatrix.forEach((row, index) => {
        placements[index] = Array(row.length).fill(false);
      });

      state.players.push({
        name,
        score: 0,
        runningScore: 0,
        specialScore: 0, // whole row (vertical or horizontal, 5 tiles of the same color)
        stages: [],
        placements,
        overflow: [],
      });

      state.activePlayer = getRandomInt(state.players.length);
    },
    computeRunningScore: (state) => {
      const { players, placementMatrix } = state;
      for (const player of players) {
        const { newPlayerScore, newSpecialScore } = computeScore({
          placementMatrix,
          player,
        });
        player.runningScore = newPlayerScore;
        player.specialScore = newSpecialScore;
      }
    },
    nextRound: (state) => {
      const { players, placementMatrix } = state;
      for (const player of players) {
        const { newPlayerScore, newPlacements, newEndGame } = computeScore({
          placementMatrix,
          player,
        });
        player.score = newPlayerScore;

        const { stages, overflow } = player;

        // move full stages to graveyard
        for (const index in stages) {
          const stage = stages[index];
          if (+stage.count >= +index + 1) {
            if (+index >= 1) {
              state.graveYard = [
                ...state.graveYard,
                ...Array(+index).fill(stage.color),
              ];
            }
            delete player.stages[index];
          }
        }

        // move overflow to graveyard
        for (const tile of overflow) {
          if (tile !== -1) state.graveYard.push(tile);
          else state.activePlayer = players.indexOf(player);
        }

        player.placements = newPlacements;
        player.overflow = [];
        state.endOfGame = state.endOfGame || newEndGame;
        state.middle = [-1];
      }
    },
    loadGraveyardInBagOdTile: (state) => {
      state.bagOfTile = [...state.graveYard];
      state.graveYard = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelected,
  setFactory,
  setTiles,
  setNumberOfPlayers,
  setNumberOfFactories,
  initBagOfTiles,
  initializeBoard,
  addPlayer,
  newGame,
  nextRound,
  computeRunningScore,
  loadGraveyardInBagOdTile,
  assignSelectedTilesToStageRow,
  assignSelectedTilesToOverflow,
} = azulSlice.actions;

export default azulSlice.reducer;
