import React from 'react';
import { connect } from 'react-redux';

import './StageRow.scss';

import {
  assignSelectedTilesToStageRow,
  computeRunningScore,
} from './redux/azulSlice';

import Tile from './components/Tile';

class StageRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async handleStageRowSelected() {
    const {
      max,
      selected,
      stageTiles,
      assignSelectedTilesToStageRow,
      index,
      placementMatrixRow,
      placementRow,
      computeRunningScore,
      disabled,
    } = this.props;

    if (disabled) {
      return;
    }

    const indexOfColor = placementMatrixRow.indexOf(selected?.color);
    const colorAlreadyPlaced = !!placementRow[indexOfColor];
    if (
      selected &&
      !colorAlreadyPlaced &&
      (!stageTiles ||
        (selected.color === stageTiles?.color && stageTiles.count < max))
    ) {
      await assignSelectedTilesToStageRow(index);
    }
    await computeRunningScore();
  }

  getTiles() {
    const {
      max,
      selected,
      stageTiles,
      placementMatrixRow,
      placementRow,
      tileSet,
    } = this.props;

    const tiles = [];

    const doesNotHaveStagedTilesOrMatchesColor =
      !stageTiles ||
      stageTiles?.length === 0 ||
      stageTiles?.color === selected?.color;
    const indexOfColor = placementMatrixRow.indexOf(selected?.color);
    const colorAlreadyPlaced = !!placementRow[indexOfColor];

    for (let i = 1; i <= max; i++) {
      const hasStagedTiles = stageTiles && i <= stageTiles?.count;
      if (hasStagedTiles) {
        const color = i <= stageTiles?.count ? stageTiles.color : '';
        tiles[i] = <Tile key={i} color={tileSet[color]} />;
      } else if (
        !this.props.disabled &&
        doesNotHaveStagedTilesOrMatchesColor &&
        !colorAlreadyPlaced
      ) {
        const color =
          i <= selected?.count + (stageTiles?.count || 0) ? selected.color : '';
        tiles[i] = <Tile key={i} color={tileSet[color]} inactive />;
      } else {
        // just backfill with empty tiles
        tiles[i] = <Tile key={i} inactive />;
      }
    }
    return tiles;
  }

  render() {
    return (
      <div
        className={['stage-row', this.props.disabled ? 'disabled' : ''].join(
          ' '
        )}
        onClick={() => this.handleStageRowSelected()}
      >
        {this.getTiles()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  tileSet: state.azulReducer.tiles,
  selected: state.azulReducer.selected,
  stageTiles:
    state.azulReducer.players[ownProps.playerId]?.stages[ownProps.index],
  placementMatrixRow: state.azulReducer.placementMatrix[ownProps.index],
  placementRow:
    state.azulReducer.players[ownProps.playerId]?.placements[ownProps.index] ||
    [],
});

export default connect(mapStateToProps, {
  assignSelectedTilesToStageRow,
  computeRunningScore,
})(StageRow);
