import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import NextRoundButton from './NextRoundButton';
import Board from './Board';
import Factory from './Factory';
import ScoreCards from './ScoreCards';

import './Game.scss';

import { setTiles } from './redux/azulSlice';
import Layout from './components/Layout';
import Settings from './Settings';
import NewGameButton from './NewGameButton';
import ScoreBoard from './ScoreBoard';
import ChangeLanguageButton from './ChangeLanguageButton';
import Help from './Help';
import Tutorial from './Tutorial';

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.setTiles(this.props.tiles);
  }

  getTutorialTarget() {
    const { players, selected, allFactoriesAndMiddleAreEmpty } = this.props;

    if (allFactoriesAndMiddleAreEmpty) {
      return {
        targetSelector: '.next-round-button',
        helpText: 'Click the next round button',
      };
    } else if (players.length <= 0) {
      return {
        targetSelector: '.new-game-button',
        helpText: 'Click the new game button',
      };
    } else if (!selected) {
      return {
        targetSelector: '.factory',
        helpText:
          'Select one of the tiles in one of the factories. Tile "1" cannot be picked, as it will go to your overflow if you pick any tile within its factory.',
      };
    } else {
      return {
        targetSelector: '.stage',
        helpText: 'Place the selected tile in one of the staging rows.',
      };
    }
  }

  render() {
    const { endOfGame, allFactoriesAndMiddleAreEmpty, activePlayerId, t } =
      this.props;

    const newGameText =
      activePlayerId < 0
        ? t('Welcome to Azul! Press the "new game" button to start.')
        : '';
    const endOfGameText = endOfGame
      ? [t('End of game! GG'), <ScoreBoard />]
      : '';
    const allFactoriesAndMiddleAreEmptyText = allFactoriesAndMiddleAreEmpty
      ? [t('End of round, press the "next round" button'), <ScoreBoard />]
      : '';
    const board =
      activePlayerId >= 0 ? <Board playerId={activePlayerId} /> : '';

    return (
      <div className="game">
        {/* <Tutorial targetObject={this.getTutorialTarget()} /> */}
        <Layout
          navBar={[
            <NextRoundButton
              key="1"
              disabled={!allFactoriesAndMiddleAreEmpty}
            />,
            <NewGameButton key="0" />,
          ]}
          navBarMiddle={<ScoreCards />}
          navBarRight={[<Help />, <ChangeLanguageButton />, <Settings />]}
          body={
            newGameText ||
            endOfGameText ||
            allFactoriesAndMiddleAreEmptyText ||
            board
          }
          leftSide={
            newGameText || endOfGame || allFactoriesAndMiddleAreEmptyText ? (
              ''
            ) : (
              <Factory />
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let allFactoriesAndMiddleAreEmpty = true;
  const {
    factories = [],
    middle = [],
    activePlayer,
    endOfGame,
  } = state.azulReducer;
  factories.forEach((factory) => {
    if (factory.length > 0) allFactoriesAndMiddleAreEmpty = false;
  });
  if (middle.length > 0) allFactoriesAndMiddleAreEmpty = false;
  return {
    allFactoriesAndMiddleAreEmpty,
    activePlayerId: activePlayer,
    endOfGame,
    players: state.azulReducer.players,
    selected: state.azulReducer.selected,
  };
};

export default connect(mapStateToProps, {
  setTiles,
})(withTranslation()(Game));
