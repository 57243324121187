import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Stage from './Stage';
import PlacementBoard from './PlacementBoard';

import './Board.scss';
import Overflow from './Overflow';
import AzulPill from './components/AzulPill';

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="board">
        <h1>
          <AzulPill color={this.props.tiles[this.props.playerId]} />
          {this.props.playerName}, {this.props.t('it is your turn to play!')}
        </h1>
        <div className="tiles">
          <div className="left">
            <Stage playerId={this.props.playerId}></Stage>
          </div>
          <div className="right">
            <PlacementBoard playerId={this.props.playerId}></PlacementBoard>
          </div>
        </div>
        <Overflow playerId={this.props.playerId} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    playerName: state.azulReducer.players[ownProps.playerId]?.name,
    score: state.azulReducer.players[ownProps.playerId]?.score,
    tiles: state.azulReducer.tiles,
  };
};

export default connect(mapStateToProps)(withTranslation()(Board));
