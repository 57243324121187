import React from 'react';
import { connect } from 'react-redux';

import Tile from './components/Tile';

import './PlacementRow.scss';

class PlacementRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRows() {
    const rows = [];
    const { placementMatrixRow: colors, placementRow, tileSet } = this.props;
    for (let i = 0; i < colors.length; i++) {
      const inactive = !placementRow[i];
      rows[i] = <Tile key={i} color={tileSet[colors[i]]} inactive={inactive} />;
    }
    return rows;
  }

  handleMouseOver() {}

  render() {
    return (
      <div className="placement-row" onMouseOver={this.handleMouseOver}>
        {this.getRows()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  tileSet: state.azulReducer.tiles,
  placementMatrixRow: state.azulReducer.placementMatrix[ownProps.index],
  placementRow:
    state.azulReducer.players[ownProps.playerId]?.placements[ownProps.index] ||
    [],
});

export default connect(mapStateToProps)(PlacementRow);
