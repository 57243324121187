import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';

import './Tutorial.scss';

import { setSelected } from './redux/azulSlice';

const tutorialRoot = document.getElementById('tutorial-root');

class Tutorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    tutorialRoot.appendChild(this.el);

    // this.resizeObserver = new ResizeObserver((entries) => {
    //   // for (let entry of entries) {
    //   const entry = entries?.[entries.length - 1];
    //   // debugger;
    //   if (!entry) return;
    //   const { target } = entry;
    //   this.redraw(target);
    //   // debugger;
    //   // if (entry.contentBoxSize) {
    //   //   entry.target.style.borderRadius =
    //   //     Math.min(
    //   //       100,
    //   //       entry.contentBoxSize.inlineSize / 10 +
    //   //         entry.contentBoxSize.blockSize / 10
    //   //     ) + 'px';
    //   // } else {
    //   //   entry.target.style.borderRadius =
    //   //     Math.min(
    //   //       100,
    //   //       entry.contentRect.width / 10 + entry.contentRect.height / 10
    //   //     ) + 'px';
    //   // }
    //   // }
    // });

    // this.resizeObserver.observe(document.querySelector('.next-round-button'));
    // this.resizeObserver.observe(document.querySelector('.new-game-button'));
    // this.resizeObserver.observe(document.querySelector('.factory'));
    // this.resizeObserver.observe(document.querySelector('.stage'));
  }

  componentWillUnmount() {
    tutorialRoot.removeChild(this.el);
  }

  redraw(target) {
    let rect = target.getBoundingClientRect();

    this.setState({
      styleOverlayTop: {
        bottom: `calc(100vh - ${rect.top}px )`,
        left: rect.left,
        right: `calc(100vw - ${rect.right}px )`,
      },
      styleOverlayBottom: {
        top: rect.bottom,
        left: rect.left,
        right: `calc(100vw - ${rect.right}px )`,
      },
      styleOverlayRight: {
        left: rect.right,
        top: rect.top,
        bottom: `calc(100vh - ${rect.bottom}px )`,
      },
      styleOverlayLeft: {
        right: `calc(100vw - ${rect.left}px )`,
        top: rect.top,
        bottom: `calc(100vh - ${rect.bottom}px )`,
      },
      styleOverlayCornerTopLeft: {
        right: `calc(100vw - ${rect.left}px )`,
        bottom: `calc(100vh - ${rect.top}px )`,
      },
      styleOverlayCornerBottomLeft: {
        right: `calc(100vw - ${rect.left}px )`,
        top: rect.bottom,
      },
      styleOverlayCornerTopRight: {
        left: rect.right,
        bottom: `calc(100vh - ${rect.top}px )`,
      },
      styleOverlayCornerBottomRight: {
        left: rect.right,
        top: rect.bottom,
      },

      // TODO: dynamic position based on window edges
      styleHelpText: { top: rect.bottom, left: rect.right },
    });
  }

  getTargetSelector() {
    const { players, selected, allFactoriesAndMiddleAreEmpty } = this.props;

    if (allFactoriesAndMiddleAreEmpty) {
      return {
        targetSelector: '.next-round-button',
        helpText: 'Click the next round button',
      };
    } else if (players.length <= 0) {
      return {
        targetSelector: '.new-game-button',
        helpText: 'Click the new game button',
      };
    } else if (!selected) {
      return {
        targetSelector: '.factory',
        helpText:
          'Select one of the tiles in one of the factories. Tile "1" cannot be picked, as it will go to your overflow if you pick any tile within its factory.',
      };
    } else {
      return {
        targetSelector: '.stage',
        helpText: 'Place the selected tile in one of the staging rows.',
      };
    }
    // return '';
  }

  render() {
    const { isOpened, targetObject } = this.props;

    const { targetSelector, helpText } = targetObject;
    // const { targetSelector, helpText } = this.getTargetSelector();

    if (isOpened && targetSelector) {
      // let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      // get element to focus on
      const target = document.querySelector(targetSelector);
      // const helpText = 'Click the new game button';

      if (target) {
        // this.resizeObserver.disconnect();
        // if (this.oldTarget) this.resizeObserver.unobserve(this.oldTarget);
        // this.resizeObserver.observe(target);

        // this.oldTarget = target;
        // debugger;
        // const targetHeight = target.offsetHeight;
        // const targetWidth = target.offsetWidth;

        let rect = target.getBoundingClientRect();

        const styleOverlayTop = {
          bottom: `calc(100vh - ${rect.top}px )`,
          left: rect.left,
          right: `calc(100vw - ${rect.right}px )`,
        };
        const styleOverlayBottom = {
          top: rect.bottom,
          left: rect.left,
          right: `calc(100vw - ${rect.right}px )`,
        };
        const styleOverlayRight = {
          left: rect.right,
          top: rect.top,
          bottom: `calc(100vh - ${rect.bottom}px )`,
        };
        const styleOverlayLeft = {
          right: `calc(100vw - ${rect.left}px )`,
          top: rect.top,
          bottom: `calc(100vh - ${rect.bottom}px )`,
        };
        const styleOverlayCornerTopLeft = {
          right: `calc(100vw - ${rect.left}px )`,
          bottom: `calc(100vh - ${rect.top}px )`,
        };
        const styleOverlayCornerBottomLeft = {
          right: `calc(100vw - ${rect.left}px )`,
          top: rect.bottom,
        };
        const styleOverlayCornerTopRight = {
          left: rect.right,
          bottom: `calc(100vh - ${rect.top}px )`,
        };
        const styleOverlayCornerBottomRight = {
          left: rect.right,
          top: rect.bottom,
        };

        // TODO: dynamic position based on window edges
        const styleHelpText = { top: rect.bottom, left: rect.right };

        // const {
        //   styleOverlayTop,
        //   styleOverlayBottom,
        //   styleOverlayRight,
        //   styleOverlayLeft,
        //   styleOverlayCornerTopLeft,
        //   styleOverlayCornerBottomLeft,
        //   styleOverlayCornerTopRight,
        //   styleOverlayCornerBottomRight,
        //   styleHelpText,
        // } = this.state;

        // TODO: add button to exit tutorial mode
        return ReactDOM.createPortal(
          <div className="tutorial">
            <div className="overlay top" style={styleOverlayTop}></div>
            <div className="overlay bottom" style={styleOverlayBottom}></div>
            <div className="overlay right" style={styleOverlayRight}></div>
            <div className="overlay left" style={styleOverlayLeft}></div>
            <div
              className="overlay corner-top-left"
              style={styleOverlayCornerTopLeft}
            ></div>
            <div
              className="overlay corner-bottom-left"
              style={styleOverlayCornerBottomLeft}
            ></div>
            <div
              className="overlay corner-top-right"
              style={styleOverlayCornerTopRight}
            ></div>
            <div
              className="overlay corner-bottom-right"
              style={styleOverlayCornerBottomRight}
            ></div>
            <div className="help-text" style={styleHelpText}>
              {helpText}
            </div>
          </div>,
          this.el
        );
      }
    }
    return <div></div>;
  }
}

// const mapStateToProps = (state) => ({
//   selected: state.azulReducer.selected,
//   numberOfFactories: state.azulReducer.numberOfFactories,
//   factories: state.azulReducer.factories,
//   middle: state.azulReducer.middle,
//   tileSet: state.azulReducer.tiles,
//   isOpened: state.azulReducer.tutorialMode,
//   players: state.azulReducer.players,
// });
const mapStateToProps = (state) => {
  let allFactoriesAndMiddleAreEmpty = true;
  const {
    factories = [],
    middle = [],
    activePlayer,
    endOfGame,
  } = state.azulReducer;
  factories.forEach((factory) => {
    if (factory.length > 0) allFactoriesAndMiddleAreEmpty = false;
  });
  if (middle.length > 0) allFactoriesAndMiddleAreEmpty = false;
  return {
    allFactoriesAndMiddleAreEmpty,
    activePlayerId: activePlayer,
    endOfGame,
    selected: state.azulReducer.selected,
    numberOfFactories: state.azulReducer.numberOfFactories,
    factories: state.azulReducer.factories,
    middle: state.azulReducer.middle,
    tileSet: state.azulReducer.tiles,
    isOpened: state.azulReducer.tutorialMode,
    players: state.azulReducer.players,
  };
};

export default connect(mapStateToProps, { setSelected })(Tutorial);
