import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import AzulButton from './components/AzulButton';

import {
  initializeBoard,
  nextRound,
  loadGraveyardInBagOdTile,
} from './redux/azulSlice';

class NextRoundButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async nextRound() {
    const { bagOfTile, nextRound, initializeBoard, loadGraveyardInBagOdTile } =
      this.props;
    await nextRound();
    if (bagOfTile.length <= 0) await loadGraveyardInBagOdTile();
    await initializeBoard();
  }

  render() {
    return (
      <div className="next-round-button">
        <AzulButton
          icon={faChevronRight}
          onClick={() => this.nextRound()}
          label={this.props.t('Next Round')}
          disabled={this.props.disabled}
          primary
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bagOfTile } = state.azulReducer;

  return {
    bagOfTile,
  };
};

export default connect(mapStateToProps, {
  initializeBoard,
  nextRound,
  loadGraveyardInBagOdTile,
})(withTranslation()(NextRoundButton));
