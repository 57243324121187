import React from 'react';

import './AzulInput.scss';

function AzulInput(props) {
  return (
    <div className="azul-input">
      <label>{props.label || <div>&nbsp;</div>}</label>
      <input
        type={props.type || 'text'}
        value={props.value}
        onInput={props.onInput}
        onChange={props.onChange}
      />
    </div>
  );
}

export default AzulInput;
