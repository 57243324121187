import React from 'react';
import { connect } from 'react-redux';

import FactoryCircle from './components/FactoryCircle';
import './Factory.scss';

import { setSelected } from './redux/azulSlice';

class Factory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFactorySelected(tile) {
    this.props.setSelected(tile);
  }

  getFactories() {
    const { numberOfFactories, selected, factories, tileSet } = this.props;
    const list = [];
    for (let i = 0; i < numberOfFactories; i++) {
      list.push(
        <FactoryCircle
          key={i}
          tiles={factories[i]}
          tileSet={tileSet}
          selectedTile={
            selected ? (selected.index === i ? selected.color : null) : null
          }
          onSelected={(tile) =>
            this.handleFactorySelected({ index: i, ...tile })
          }
        />
      );
    }
    return list;
  }

  getMiddle() {
    const { middle, selected, tileSet } = this.props;
    return (
      <FactoryCircle
        tiles={middle}
        tileSet={tileSet}
        selectedTile={
          selected ? (selected.index === -1 ? selected.color : null) : null
        }
        onSelected={(tile) =>
          this.handleFactorySelected({ index: -1, ...tile })
        }
      />
    );
  }

  render() {
    return (
      <div className="factory">
        {this.getFactories()}
        {this.getMiddle()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selected: state.azulReducer.selected,
  numberOfFactories: state.azulReducer.numberOfFactories,
  factories: state.azulReducer.factories,
  middle: state.azulReducer.middle,
  tileSet: state.azulReducer.tiles,
});

export default connect(mapStateToProps, { setSelected })(Factory);
