import React from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import './ScoreCards.scss';
import './ScoreCard.scss';
import AzulPill from './components/AzulPill';

import Modal from './components/Modal';
import PlacementBoard from './PlacementBoard';
import AzulButton from './components/AzulButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Stage from './Stage';

function ScoreCard(props) {
  const { player, color, onClick } = props;
  return (
    <div className="score-card" onClick={onClick}>
      <AzulPill color={color} />
      <div className="player-name">{player.name}</div>
      <div className="score">{player.runningScore + player.specialScore}</div>
      <div className="detail">
        <div className="tile-score">{player.runningScore}</div>
        <div className="special-score">{player.specialScore}</div>
      </div>
    </div>
  );
}

class ScoreCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      playerId: -1,
      player: null,
    };
  }

  handlePlayerClicked(player, index) {
    console.log(player);
    this.setState({
      player: player,
      playerId: index,
    });
    this.openBoard();
  }

  openBoard() {
    this.setState({
      modalOpened: true,
    });
  }

  closeBoard() {
    this.setState({
      modalOpened: false,
    });
  }

  getPlayers() {
    const { players, tiles } = this.props;
    return players.map((player, index) => (
      <ScoreCard
        key={index}
        player={player}
        color={tiles[index]}
        onClick={() => this.handlePlayerClicked(player, index)}
      />
    ));
  }

  render() {
    return (
      <div>
        <div className="score-cards">{this.getPlayers()}</div>
        <Modal
          isOpened={this.state.modalOpened}
          withOverlay
          onOverlayClick={() => this.closeBoard()}
          header={
            <div
              style={{
                display: 'inline-flex',
                height: '24px',
                lineHeight: '24px',
                textAlign: 'center',
              }}
            >
              <AzulPill color={this.props.tiles[this.state.playerId]} />
              {this.props.t('board', { player: this.state?.player?.name })}
            </div>
          }
          // left={<AzulButton label="Blah" primary />}
          footer={[
            <AzulButton
              icon={faTimes}
              onClick={() => this.closeBoard()}
              label={this.props.t('Close')}
            />,
          ]}
        >
          <div style={{ display: 'inline-flex', margin: '8px' }}>
            <Stage disabled playerId={this.state.playerId} />
            <PlacementBoard playerId={this.state.playerId} />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  players: state.azulReducer.players,
  tiles: state.azulReducer.tiles,
});

export default connect(mapStateToProps, {})(withTranslation()(ScoreCards));
